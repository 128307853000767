import React from 'react';
import { motion } from 'framer-motion';
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import ParallaxWrapper from "../ui/parallaxWrapper"
import shouldTextBeBlack from '../../hooks/shouldTextBeBlack';

const ProjectCard = ({ 
    categories,
    slug,
    touchImage,
    title,
    client,
    index,
    setFocusedProject,
    touchColor,
    projectsEditorial }) => {
                       

    const IsEven = (index % 2 === 0) ? true : false   
        
    
    let isLight = shouldTextBeBlack(touchColor?.hex);

    return (      
        <>  
            {projectsEditorial ? (
                <Link to={`/projects/${slug && slug.current && slug.current}`} className="flex w-full h-full card-editorial">
                    <ParallaxWrapper mass={IsEven ? 0.3 : 0.7} offset={IsEven ? 30 : 60} className="wrapper relative w-full group">                    
                        {touchImage && touchImage.asset && (
                        <motion.div   
                            className="relative lg:overflow-hidden"
                            style={{ borderRadius: '100%' }}               
                            initial={{ opacity: 0, scale: 0.75 }}
                            whileInView={{ opacity: 1, scale: 1 }}                        
                            transition={{ duration: 0.5 }}
                            viewport={{ once: false }}                    
                        >
                            <div className={`hidden lg:block z-10 absolute top-1/2 left-0 w-full transition-all duration-400 transform -translate-x-full -translate-y-1/2 trans-std xl:group-hover:translate-x-0 h-px ${isLight ? 'bg-black' : 'bg-white'}`}></div>
                            
                            {touchImage && touchImage.asset && (
                                <div
                                    onMouseEnter={() => setFocusedProject(index)}
                                    onMouseLeave={() => setFocusedProject(null)} 
                                >
                                    <GatsbyImage                           
                                    
                                    image={touchImage.asset.gatsbyImageData} 
                                    alt={touchImage.asset.altText} 
                                    className="aspect-square object-cover transition-all duration-400 xl:group-hover:blur-md" 
                                    />     

                                </div>                                
                            )}  

                            <p className={`trans-std lg:absolute lg:top-1/2 lg:left-0 lg:transform lg:translate-y-2 lg:w-1/2 lg:px-2 text-left pointer-events-none xl:opacity-0 duration-400 xl:group-hover:opacity-100 lg:text-xxs ${isLight ? '' : 'lg:text-white'}`}>
                            {title && title}
                            </p>  

                            <p className={`trans-std lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:translate-y-2 lg:w-1/2 text-left pr-2 pointer-events-none xl:opacity-0 duration-400 xl:group-hover:opacity-100 text-xxs ${isLight ? '' : 'lg:text-white'}`}>                    
                            {client && client}                                        
                            </p>     
                                                                                            
                        </motion.div>
                        )}                                        
                    </ParallaxWrapper>
                </Link>
            ) : (
                <div className="w-full flex justify-start relative">
                    <Link 
                        to={`/projects/${slug && slug.current && slug.current}`} 
                        className="flex items-baseline"
                        onMouseEnter={() => setFocusedProject(index)}
                        onMouseLeave={() => setFocusedProject(null)} 
                    >
                        {title && <p className="title">{title}</p>}
                        {client && <p className="text-sm ml-4">{client}</p>}
                    </Link>
                    {touchImage && touchImage.asset && (
                        <div className="hidden lg:block absolute right-0 top-0 w-1/2 max-w-xs trans-std opacity-0">
                            <GatsbyImage                           
                                image={touchImage.asset.gatsbyImageData} 
                                alt={touchImage.asset.altText} 
                                className="aspect-square object-cover rounded-full" 
                            />   
                        </div>                     
                    )}
                </div>
            )}  
        </>      
    )
}

export default ProjectCard