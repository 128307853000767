import React from 'react'


//SVG
import ListIcon from '../../svg/projectListIcon.svg';
import EditorialIcon from '../../svg/projectEditorialIcon.svg';

const LayoutToggle = ({ className, projectsEditorial, setProjectsEditorial, resetProjectScroll }) => {

function handleClick(editorialLayout) {
    setProjectsEditorial(editorialLayout)
    resetProjectScroll()
}
  return (
    <div className={className}>
        <div 
            role="button" 
            tabIndex="0"
            className={projectsEditorial && 'opacity-60'}
            onClick={() => handleClick(false)} 
        >
            <ListIcon />
        </div>
        <div
            role="button" 
            tabIndex="0"
            className={!projectsEditorial && 'opacity-60'}
            onClick={() => handleClick(true)} 
        >
            <EditorialIcon />
        </div>
    </div>
  )
}

export default LayoutToggle