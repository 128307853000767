import React from 'react'

const LabelFilter = ({ text, active, onClick }) => {

  function handleClick() {     
    onClick()
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      handleClick()
    }
  }

  return (
    <div 
        onClick={handleClick}
        onKeyDown={handleKeyDown}          
        role="button"
        tabIndex="0"                 
        className={active ? 'active' : 'inactive'}
    >
        {text}
    </div>
  )
}

export default LabelFilter