import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import cx from 'classnames';

//State
import { useSiteState } from '../../lib/siteContext';

//Components
import LabelFilter from '../ui/labelFilter';
import ActiveFilters from '../ui/activeFilters';
import ShowFilterToggle from './showFilterToggle';
import LayoutToggle from './layoutToggle';

//Animation
import { filterDrawReveal } from '../../lib/animation';


const ProjectsFilters = ({ 
  filters,
  setFilters,
  filterFeatures,
  filterIndustries,
  filterCategories,
  filtersOpen,
  setFiltersOpen,
  shownProjects,
  setSearchProjects,
  resetProjectScroll,
  sortByName,
  setSortByName
  }) => {  

  const filtersRef = useRef()

  function filterExists(name, group) {
    return (
        filters.find((f) => f.name === name && f.group === group) !== undefined
    );
  }
  
  function addFilter(name, group, fnc) {
    setFilters((currentFilters) => [...currentFilters, { name, group, fnc }]);
  }
  
  function removeFilter(name, group) {
    setFilters((currentFilters) =>
        currentFilters.filter((f) => !(f.name === name && f.group === group))
    );
  }
  
  function toggleFilter(name, group, fnc) {
    if (filterExists(name, group)) {
        removeFilter.apply(null, arguments);
    } else {
        addFilter.apply(null, arguments);
    }    
  }     
  
  function filterInView() {
    filtersRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const { projectsEditorial, setProjectsEditorial, headerIsPinned } = useSiteState();  

  const pinPosition = cx({
    'top-header': headerIsPinned === true,        
    'top-0': headerIsPinned === false,            
    });

    //Search
    const [searchValue, setSearchValue] = useState();
    const arraySearch = (array, keyword) => {      
      const searchTerm = keyword.toLowerCase()
      return array.filter(value => {
          return value.node.title.toLowerCase().match(new RegExp(searchTerm, 'g'))
      })
  }
  
  const handleSearch = async (e) => {
      let value = e.target.value;      
      setSearchValue(value);
      if (value.length > 2) {
        let search = await arraySearch(shownProjects, value);
        setSearchProjects(search)
      } else {
        setSearchProjects(undefined)
      }
    }

    function handleSortChange(selVal) {
      setSortByName(selVal === `featured` ? false : true)
    }

  return (    
    <div ref={filtersRef} id="filter-wrapper" className={`${pinPosition} ${filtersOpen ? 'bg-opacity-30 backdrop-blur-lg' : ''}`}>
      <div className="w-full flex justify-between items-start gap-x-4 px-gutter">
        <div className="flex flex-col lg:flex-row items-center">          
          <ShowFilterToggle className="text-black" filtersOpen={filtersOpen} setFiltersOpen={setFiltersOpen} filterInView={filterInView} />
          <ActiveFilters filters={filters} setFilters={setFilters} />
        </div>
        <div className="flex items-center gap-2">
          <span className="text-sm">Sort: </span>
          <select 
            className="appearance-none bg-transparent text-current outline-none cursor-pointer rounded-full border border-current py-1 px-3 xl:hover:border-dashed text-center mr-4"
            onChange={(val) => handleSortChange(val.target.value)}>
            <option key={1} value={'featured'}>Featured</option>
            <option key={2} value={'alphabetical'}>A → Z</option>
          </select>
        <LayoutToggle className="flex gap-x-4" projectsEditorial={projectsEditorial} setProjectsEditorial={setProjectsEditorial} resetProjectScroll={resetProjectScroll} />                        
        </div>        
      </div>  
      <AnimatePresence exitBeforeEnter>
        {filtersOpen && (
          <motion.div
            id="project-filters"                      
            variants={filterDrawReveal}
            initial="closed"
            animate="open"
            exit="closed"              
          >
            <div className="col-span-6 flex justify-start lg:hidden">
              <button className="btn-icon" onClick={() => setFiltersOpen(!filtersOpen)}>                
                <svg className={`transform ${filtersOpen ? 'rotate-45' : ''}`} width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 3V13M11 8H1H11Z" className="stroke-current" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <span>{filtersOpen ? 'Close' : 'Filter' }</span>
                </button>
            </div>
            <div className="col-span-6 lg:col-span-full">
              <input type="text" className="projects-search" placeholder="Search projects"   onChange={handleSearch} value={searchValue}/>
            </div>
            <div className="col-span-6 lg:col-span-5">        
              <div className="filter-label">Categories</div>          
              <ul className="filter-categories">                    
                {filterCategories.edges.map((item) => {
                return (
                  <li>
                    <LabelFilter
                      text={item.node.title}
                      active={filterExists(item.node.title, "category")}
                      onClick={() =>
                        toggleFilter(item.node.title, "category", (project) => {
                          var projectIsShown = false
                          project.categories.map((projectCategory) => (                              
                          (projectCategory.title === item.node.title) ? projectIsShown = true : projectIsShown = false
                          ))                             
                          return projectIsShown
                        })
                      }                            
                    />                                                
                  </li>  
                )
              })}
              </ul>
            </div>            
            <div className="col-span-6 lg:col-span-13">
              <div className="filter-label">Features</div>
              <ul className="filter-tag-list mt-2">
                  {filterFeatures.map((item) => (
                      <li>
                      <LabelFilter
                        text={item.title}
                        active={filterExists(item.title, "feature")}
                        onClick={() =>
                          toggleFilter(item.title, "feature", (project) => {
                            var projectIsShown = false
                            project.features.map((projectFeature) => (                              
                            (projectFeature.title === item.title) ? projectIsShown = true : projectIsShown = false
                            ))                             
                            return projectIsShown
                          })
                        }                            
                      />                                                
                    </li>  
                  ))}
              </ul>
              </div>
            <div className="col-span-6">
              <div class="filter-label">Industry</div>
              <ul className="filter-tag-list mt-2">
              {filterIndustries.map((item) => {
                return (
                  <li>
                  <LabelFilter
                    text={item.title}
                    active={filterExists(item.title, "industry")}
                    onClick={() =>
                      toggleFilter(item.title, "industry", (project) => {
                        var projectIsShown = false
                        project.industries.map((projectIndustry) => (                              
                        (projectIndustry.title === item.title) ? projectIsShown = true : projectIsShown = false
                        ))                             
                        return projectIsShown
                      })
                    }                            
                  />                                                
                </li>  
                )
              })}
              </ul>
            </div>
            <div className="col-span-full w-screen h-6"></div>
          </motion.div>
        )}       
      </AnimatePresence>           
    </div>
  )
}

export default ProjectsFilters