import React from 'react'

const ActiveFilters = ({ filters, setFilters }) => {

  function removeFilter(name, group) {
    setFilters((currentFilters) =>
        currentFilters.filter((f) => !(f.name === name && f.group === group))
    );
  }

  return (
    <div className="flex items-baseline flex-wrap">
        {filters.length > 0 && (
            <button
            className="appearance-none trans-fast mr-3 flex items-center cursor-pointer xl:hover:text-filterTint"
            onClick={() => setFilters([])} 
        >
            <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="stroke-current" d="M8.53542 8.53542L1.46436 1.46436M8.53542 1.46436L1.46436 8.53542" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Clear all
        </button>
        )}        
        {filters.map((filter) => (
        <button
            className="appearance-none trans-fast mr-3 flex items-center cursor-pointer xl:hover:text-filterTint"
            onClick={() => removeFilter(filter.name, filter.group)} 
        >
            <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="stroke-current" d="M8.53542 8.53542L1.46436 1.46436M8.53542 1.46436L1.46436 8.53542" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {filter.name}
        </button>
        ))}             
    </div>
  )
}

export default ActiveFilters