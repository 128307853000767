import React, { useEffect, useState, useRef } from 'react';
import { graphql } from 'gatsby';

import { motion, AnimatePresence } from 'framer-motion';
import { useWindowWidth } from '@react-hook/window-size'


import { useSiteState } from '../lib/siteContext';
import { applyProjectFilters, applyProjectFiltersExcluded } from "../utils/projectFiltering";

// Components
import Seo from '../components/global/seo';
import Layout from '../components/layout';
import ProjectsFilters from '../components/projects/projectsFilters';
import ProjectCard from '../components/projects/projectCard';
import PackagingBanner from "../components/components/packagingBanner"

//Animation
import { projectListVariants, projectMaskVariants } from '../lib/animation';
import { PortableTextBlock } from '../components/ui/portableTextBlock';

const ProjectsPage = ({ data: { pageData, projects, projectsList, filtersFeatures, filtersIndustries, filterCategories } }) => {

  const {    
    headerTheme,
    pageTheme,    
    myPorterCta,
    footerTheme,
    seo,
    bannerImage,
    introColOne,
    introColTwo,
    filterFeatures,
    filterIndustries,      
   } = pageData;
  
  const { 
    setMyPorter, 
    setFooterHidden, 
    setHeaderDark, 
    projectsEditorial, 
    setFooterTheme, 
    setFooterForms 
  } = useSiteState();

  const windowWidth = useWindowWidth()

  // useEffect(()=>{
    // window.projects = projects.edges.map(x=>[x.node.title, x.node.hidden])

    // console.log('projectList',projectsList)
    // console.log('projects',projects)
  // },[])

  useEffect(() => {  
    if (pageTheme.color.value) {
      document.documentElement.setAttribute('data-theme', `theme-${pageTheme.color.title}`);
      document.documentElement.style.setProperty(`--doc-bg`, pageTheme.color.value);      
    }                  
    setMyPorter(myPorterCta === true ? true : false)
    setFooterForms(false)
    setHeaderDark(headerTheme.color.title === "Black" ? true : false)
    setFooterTheme(footerTheme.color.title ? footerTheme.color.title : 'theme-none')   
    setFooterHidden(false)       
  }, [pageTheme, setFooterHidden, headerTheme, myPorterCta, setMyPorter, setHeaderDark, footerTheme, setFooterTheme, setFooterForms]);

  const [filters, setFilters] = useState([]);
  const [filtersOpen, setFiltersOpen] = useState(false);

  // let filteredList = projectsList.edges.filter(x => x.node.hidden)

  let shownProjects = applyProjectFilters(projectsList, filters);
  let hiddenProjects = applyProjectFiltersExcluded(projectsList, filters);
  
  
  const [focusedProject, setFocusedProject] = useState(null)  
  const [searchProjects, setSearchProjects] = useState(undefined)
  const [sortByName, setSortByName] = useState(false);
  const sortedProjects = [...shownProjects].sort((a, b) => {
    if (sortByName === false) {
      return a.node.order - b.node.order;
    }
    return a.node.title.localeCompare(b.node.title);
  });

  const projectsRef = useRef()

  function resetProjectScroll() {
    projectsRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Layout>   
      
      {seo && <Seo {...seo} />}
      
      <section className="grid-std gap-y-6 grid-flow-row">
        
        {bannerImage && <div className="col-span-full"><PackagingBanner bannerImage={bannerImage} /></div>}
      
        {introColOne && !bannerImage && <div className="col-span-full mt-header"></div>}
      
        {introColOne && (
          <PortableTextBlock 
            text={introColOne} 
            className="col-start-2 col-end-8 lg:col-end-16 2xl:col-end-15 prose max-w-none"
          />
        )}
      
        {introColOne && introColTwo && (
          <PortableTextBlock 
            text={introColTwo} 
            className="col-start-2 col-end-8 lg:col-start-17 lg:col-end-26 prose max-w-none"
          />
        )}
      
      </section>
      
      <section 
        className="trans-lng grid grid-cols-1 items-start relative" 
        style={{ 
          backgroundColor: projects.edges[focusedProject] && projects.edges[focusedProject].node.touchColor && (windowWidth > 1279)
            ? projects.edges[focusedProject].node.touchColor.hex 
            : ''}}
      >          
        
        {/* Spacer */}
        <div ref={projectsRef} className="row-start-1 col-span-full block w-screen h-header relative"></div>        

        {/* Projects Filters */}                
        <ProjectsFilters
          {...{          
            filters,
            setFilters,
            amountVisible: shownProjects.length,
            amountTotal: projects.length,
            filterFeatures,
            filterIndustries,            
            filterCategories,
            filtersOpen,
            setFiltersOpen,
            searchProjects,
            setSearchProjects,
            shownProjects,
            resetProjectScroll,
            sortByName,
            setSortByName
          }}        
        />
        
        {/* Projects Filters Mask */}
        <AnimatePresence exitBeforeEnter>
          
          {filtersOpen && 
          <motion.div 
            className="absolute inset-0 z-1 lg:bg-black/10 backdrop-blur-xs cursor-n-resize"
            onClick={() => setFiltersOpen(!filtersOpen)}
            variants={projectMaskVariants}
            initial="exit"
            animate="enter"
            exit="exit"
          >
          </motion.div>
          }

        </AnimatePresence>
        
        {/* Projects Grid/List */}
        <AnimatePresence exitBeforeEnter>
          
          <motion.ul             
            key={projectsEditorial}
            className={`${projectsEditorial ? 'projects-editorial' : 'projects-list'} row-start-2 col-span-full w-screen mt-20 lg:mt-40 pb-44 lg:pb-64 relative`
            }
            variants={projectListVariants}
            initial="exit"
            animate="enter"
            exit="exit"
          >                  
            
            {searchProjects !== undefined ? (
              <>
              {searchProjects.map(({ node }, index) => {
                return (
                  <li className="">
                    <ProjectCard 
                      {...node} 
                      setFocusedProject={setFocusedProject} 
                      index={index} 
                      projectsEditorial={projectsEditorial} 
                      />
                  </li>
                )
              })}
              </>
            ) : (
              <>
              {sortedProjects.length > 0 ? sortedProjects.map(({ node }, index) => (
              <li className="">
              <ProjectCard 
                {...node} 
                setFocusedProject={setFocusedProject} 
                index={index} 
                projectsEditorial={projectsEditorial} 
                />
            </li>
            )) : (
              <div className="col-span-full text-md">
                No matches found for this filter selection.
              </div>
            )} 
              </>
            )}

                     
          </motion.ul>     

        </AnimatePresence>        
      
      </section> 
    
    </Layout>
  );
};

export default ProjectsPage;

export const projectsQuery = graphql`
  query projectsQuery {
    pageData: sanityProjects {
      seo {
        ...SEO
      }
      title
      pageTheme {
        color {
          value
          title
        }
      }                  
      headerTheme {
        color {
          value
          title
        }
      }
      showSorting
      filterFeatures {
        title
      }
      filterIndustries {
        title
        slug {
          current
        }
      }
      footerTheme {
          color {
          value
          title
          }
      }
      bannerImage {
        asset {
            gatsbyImageData
        }
    }
      introColOne: _rawIntroColOne(resolveReferences: {maxDepth: 10})
      introColTwo: _rawIntroColTwo(resolveReferences: {maxDepth: 10})
    }    
    filtersFeatures:  allSanityProductFeature(sort: {fields: title}) {
      edges {
        node {
          title
        }
      }
    }
    filtersIndustries: allSanityIndustry(sort: {fields: title}) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    filterCategories: allSanityCategory(sort: {fields: title}) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    # (filter: {hidden: {ne: true}})
    projects: allSanityProject {
        edges {
          node {
            order
            title
            client
            hidden
            industries {
              title
              slug {
                current
              }
            }
            categories {
              title
              slug {
                current
              }
            }
            features {
              title
            }
            touchImage {
                asset {
                    url
                    gatsbyImageData
                    altText
                }
            }
            slug {
              current
            }
            touchColor {
              hex
            }
          }
        }
      }
      # , filter: {hidden: {ne: true}}
      projectsList: allSanityProject(sort: {order: ASC, fields: title}) {
        edges {
          node {
            order
            title
            client
            hidden
            industries {
              title
              slug {
                current
              }
            }
            categories {
              title
              slug {
                current
              }
            }
            features {
              title
            }
            touchImage {
                asset {
                    url
                    gatsbyImageData
                    altText
                }
            }
            slug {
              current
            }
            touchColor {
              hex
            }
          }
        }
      }
  }
`;
