import React from 'react'

const ShowFilterToggle = ({ className, filtersOpen, setFiltersOpen, filterInView }) => {
  
  function handleClick() {
    setFiltersOpen(!filtersOpen)
    filterInView && filterInView()
  }

  return (
    <div className={`${className}`}>
      <div
        role="button"
        tabIndex={0}
        className={`mb-4 lg:mb-0 lg:mr-4 btn-icon`}
        onClick={() => handleClick()} 
        >
        <svg className={`transform ${filtersOpen ? 'rotate-45' : ''}`} width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 3V13M11 8H1H11Z" className="stroke-current" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/></svg>
        <span>{filtersOpen ? 'Close' : 'Filter' }</span>
    </div> 
    </div>
  )
}

export default ShowFilterToggle