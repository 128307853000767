import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from 'framer-motion';

const PackagingBanner = ({ bannerImage }) => {

  return (
    <motion.div                
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.1, duration: 0.5 }}
          viewport={{ once: true }}
          >
           <GatsbyImage          
            loading="eager"
            image={bannerImage.asset.gatsbyImageData} 
            alt={bannerImage.asset.alt} 
            className="w-full h-auto max-h-projectBanner object-cover"
          />    
    </motion.div>
  )
}

export default PackagingBanner